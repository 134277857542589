import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="JobScraper.io" link="https://github.com/ianmat55/JobScraper.io" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Job board scraper web app built with Node/Express and ejs.
    </ProjectCard>
    <ProjectCard title="CLI Socket Chatbot" link="https://github.com/ianmat55/socket-chatbot" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Server/client chatbot CLI utilizing Python's socket and rich libraries.
    </ProjectCard>
    <ProjectCard title="Country Search React App" link="https://github.com/ianmat55/React_Country_Search" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Search bar that filters and displays country info pulled from REST Countries API. React app requests info from OpenWeather API. 
    </ProjectCard>
    <ProjectCard title="Coding Challenges" link="https://ianmat55.github.io/JS-Challenges/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Collection of coding challenges.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      